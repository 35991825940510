import { defineStore } from "pinia";

export const useDownloaderStore = defineStore("downloaderStore", {
  state: () => ({
    downloaderPolicyId: "" as string,
    downloaderPolicyNumber: "" as string,
    downloaderTableRecordId: "" as string,
    downloaderProposerId: "" as string,
    downloaderDocumentId: "" as string,
    downloaderDocumentName: "" as string,
    downloaderFinalLinkGenerated: false,
    downloaderModalShow: false,
    downloaderFinalLink: "" as string | undefined,
  }),
  actions: {
    setDownloader(
      downloaderPolicyId: string,
      downloaderPolicyNumber: string,
      downloaderTableRecordId: string,
      downloaderProposerId: string,
      downloaderDocumentId: string,
      downloaderDocumentName: string
    ) {
      this.downloaderPolicyId = downloaderPolicyId;
      this.downloaderPolicyNumber = downloaderPolicyNumber;
      this.downloaderTableRecordId = downloaderTableRecordId;
      this.downloaderProposerId = downloaderProposerId;
      this.downloaderDocumentId = downloaderDocumentId;
      this.downloaderDocumentName = downloaderDocumentName;
      this.downloaderModalShow = true;
      this.downloaderFinalLinkGenerated = false;
    },
  },
});
