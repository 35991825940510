import { useDownloaderStore } from "~/stores/useDownloaderStore";
import { PDFDocument } from "pdf-lib";
import { useToast } from "vue-toastification";

export default function useDocumentDownloader() {
  const downloaderStore = useDownloaderStore();
  const { getDocument, markDocumentAsRead } = useApi();

  const showDownloader = () => {
    downloaderStore.downloaderModalShow = true;
  };

  const hideDownloader = () => {
    downloaderStore.downloaderModalShow = false;
  };

  const isGenerated = () => {
    return downloaderStore.downloaderFinalLinkGenerated;
  };

  const showModal = () => {
    return downloaderStore.downloaderModalShow;
  };

  const getFinalLink = () => {
    return downloaderStore.downloaderFinalLink;
  };

  const convertAndSetTitle = async (base64Pdf: string, newTitle: string) => {
    try {
      const pdfBytes = Uint8Array.from(atob(base64Pdf), (c) => c.charCodeAt(0));
      const pdfDoc = await PDFDocument.load(pdfBytes);

      pdfDoc.setTitle("GoShorty " + newTitle);

      const modifiedPdfBytes = await pdfDoc.save();
      const modifiedPdfBlob = new Blob([modifiedPdfBytes], {
        type: "application/pdf",
      });
      const modifiedPdfUrl = URL.createObjectURL(modifiedPdfBlob);

      return { success: true, downloadLink: modifiedPdfUrl };
    } catch (error) {
      return {
        success: false,
        error: error,
      };
    }
  };

  const getDownloadURLObject = async (
    base64Pdf: string,
    documentTitle: string
  ) => {
    const { success, downloadLink, error } = await convertAndSetTitle(
      base64Pdf,
      documentTitle
    );

    if (success) {
      return { success: true, downloadLink };
    } else {
      return { success: false, error };
    }
  };

  const doDocumentDownloadHandler = async (
    tableRecordId: string,
    policyId: string,
    policyNumber: string,
    proposerId: string,
    documentId: string,
    documentName: string
  ) => {
    // Set the values and loader
    downloaderStore.setDownloader(
      policyId,
      policyNumber,
      tableRecordId,
      proposerId,
      documentId,
      documentName
    );

    // Download the document
    const { success: getDocumentSuccess, documentBase64 } = await getDocument(
      tableRecordId,
      policyId,
      policyNumber,
      proposerId,
      documentId,
      documentName
    );

    if (!getDocumentSuccess) {
      useToast().error(
        "There was an error downloading your document, please try again or contact info@goshorty.co.uk."
      );
      hideDownloader();
      return;
    }

    const { success: getDownloadURLObjectSuccess, downloadLink } =
      await getDownloadURLObject(documentBase64, documentName);

    if (!getDownloadURLObjectSuccess) {
      useToast().error(
        "There was an error downloading your document, please try again or contact info@goshorty.co.uk."
      );
      hideDownloader();
    } else {
      downloaderStore.downloaderFinalLink = downloadLink;
      downloaderStore.downloaderFinalLinkGenerated = true;
    }
  };

  const doDocumentMarkAsRead = async () => {
    const { success } = await markDocumentAsRead(
      downloaderStore.downloaderPolicyId,
      downloaderStore.downloaderProposerId,
      downloaderStore.downloaderPolicyNumber,
      downloaderStore.downloaderDocumentId,
      downloaderStore.downloaderDocumentName
    );

    if (!success) {
      return { success: false };
    }

    return {
      success: true,
    };
  };

  return {
    doDocumentDownloadHandler,
    doDocumentMarkAsRead,
    showDownloader,
    hideDownloader,
    isGenerated,
    showModal,
    getFinalLink,
  };
}
